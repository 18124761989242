import React, { useState } from "react"
import {StyledTabHeader, StyledTabSection, StyledTabs} from "../reusable.styles.js"

import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { queries } from "../breakpoints"
import styled from 'styled-components';

const FAQs = ({ data, location }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      heading: "New client",
      faqs:[
        {
          header: "What is Grubhub?",
          description: "Grubhub is a fast and easy way to order food online from the best local restaurants and caterers. For businesses, it’s a cost-savings tool that simplifies and streamlines the process of ordering and expensing food."
        },
        {
          header: "What is a Grubhub Corporate Account?",
          description: "A Grubhub corporate account connects you and your teams with delicious meals from your favorite local restaurants. With group ordering, catering and corporate-friendly features like budgets, rules and consolidated billing\u2014feeding the office has never been easier."
        },
        {
          header: "How much does a Grubhub Corporate Account cost?",
          description: `<span>It’s free to sign up for a Corporate Account and you’ll only pay for the meals that are actually ordered. <a href="/contact-us/">Contact us for more detailed pricing inquiries.</a></span>`
        },
        {
          header: "How do I open a Grubhub Corporate Account?",
          description: `<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href="/contact-us/">Contact us to learn more about getting started.</a></span>`
        },
        {
          header: "Can I order food delivery to multiple addresses?",
          description: `<span>Yes, with a <a href="/">Grubhub Corporate Account</a> you can order food delivery to multiple addresses. Individual meals allow your team to order for themselves to any address across the country based on the budget you set. Learn more about <a href="/individual-meals/">individual meals</a>.</span>`
        },
        {
          header: "What is a Grubhub line of credit?",
          description: `<span>A Grubhub line of credit is a simple way to provide meals for your team, eliminating the need for credit cards or expense reports. Get one monthly invoice for all food expenses and only pay for the meals that are actually ordered. <a href="/">Learn more</a></span>`
        },
        {
          header: "How does Scheduled Team Order work?",
          description: "Employees place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution."
        },
        {
          header: "How do Individual meals work?",
          description: `<span>Individual meals consolidate all meals into one fully-coded invoice and allow teams to order with allocated budgets. <a href="/individual-meals/"> Learn more about individual meals.</a></span>`
        },
        {
          header: "What is Grubhub Perks?",
          description: "Perks from Grubhub offers exclusive food delivery deals and rewards from your employees' favorite restaurants. Your company doesn't pay more, but your team members get more."
        },
        {
          header: "How far will Grubhub deliver?",
          description: "Delivery distances vary by restaurant. Check on Grubhub.com and enter your address to see which restaurants deliver to your office."
        },
        {
          header: "Can Grubhub leave food at the door?",
          description: "Grubhub drivers can leave food with a doorman or receptionist, or you can meet the driver yourself."
        }
      ]
    },
    {
      heading: "Existing client",
      faqs:[
        {
          header: "Where can I use my corporate account?",
          description: "You can order online from a variety of restaurants throughout many cities. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
        },
        {
          header: "Can I cancel a corporate account order?",
          description: `<span>If you need help with a corporate order, you can always email <a target="_blank" rel="noreferrer" href="mailto:corporatecare@grubhub.com">corporatecare@grubhub.com</a>, call <a href="tel:800-905-9322">800-905-9322</a> ext 2, or live chat the team to get immediate help.</span>`
        },
        {
          header: "How do I access the Grubhub Corporate Admin Portal?",
          description: 'Sign into your account and locate the "Hi, name" in the upper right hand corner. Click on the down arrow next to your name for the drop down then click "Group Administration".'
        },
        {
          header: "Who should I reach out to regarding issues with my Grubhub order?",
          description: `<span>Please reach out to our dedicated Corporate Care team at <a href="tel:844-478-2249">844-478-2249</a> or email us at <a target="_blank" rel="noreferrer" href="mailto:corporatecare@grubhub.com">corporatecare@grubhub.com</a></span>`
        },
        {
          header: "Who should I reach out to regarding issues with my Grubhub account?",
          description: `<span>You can contact your dedicated Client Success Manager, or reach out to Client Relations between 8:30AM and 6PM EST, M-F at at <a href="tel:844-478-2249">844-478-2249</a>, Option 3 or email us at <a target="_blank" rel="noreferrer" href="mailto:clients@grubhub.com">clients@grubhub.com</a>.</span>`
        },
        {
          header: "How do I add multiple locations to my Line of Credit (LOC)?",
          description: 'You can set up (up to 50) office locations under a single LOC. You can create ‘groups’ of employees with different budgets under the same LOC that can apply over multiple locations. Contact your Client Success Manager or Client Relations to set this up.'
        },
        {
          header: "What do I do if the link to set my Grubhub Corporate Account password has expired?",
          description: `<span>Password links expire after three days. If you need a new link, please ask your account administrator or our Client Relations Team, <a target="_blank" rel="noreferrer" href="mailto:clients@grubhub.com">clients@grubhub.com</a>, to send a new welcome email.</span>`
        }
      ]
    }
  ]

  let faqQuestions = [];
  tabs.forEach(singleTab => {
    faqQuestions = faqQuestions.concat(singleTab.faqs);
  });

  return (
    <Layout
      location={location}
      title={"FAQs | Grubhub Corporate | Corporate Ordering Questions"}
      description={"We answered the most frequently asked questions about Grubhub Corporate Accounts. From perks to group order, we've got you covered."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "learn more",
        pageName: "faq",
        brand: "grubhub"
      }}
      faqSeoList={faqQuestions}
    >
      <GeneralHeader 
        background="radial-gradient(circle, #785FD5 0%, var(--color-primary-darker) 100%)"
      >
          <Container className="container">
            <TextContainer>
              <Headline>Questions?</Headline>
              <Subheadline>We answered the most frequently asked questions. From Perks to group orders, we’ve got you covered.</Subheadline>
            </TextContainer>
            <ImageContainer>
              <StaticImage
                alt="FAQs"
                placeholder="blurred"
                width={450}
                height={350}
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277584/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/5-FAQ/MOBILE-FAQ-HeaderImage.png"
              />
            </ImageContainer>
          </Container>
      </GeneralHeader>
      <StyledTabSection backgroundColor="white" >
        <StyledTabs value={value} onChange={handleChange}>
          {/* Render the tab titles based on the tabs array set above */}
          {tabs.map((tab, i) => (
              <StyledTabHeader label={tab.heading} key={i} />
          ))}
        </StyledTabs>

        {/* Render the tab panels */}
        {tabs.map((tab, i) => (
          <TabPanel value={value} index={i} key={i} className="homepage-tabbed-cards-section__tab-panel row">
            <FaqAccordionSection faqs={tab.faqs} backgroundColor="transparent" sectionPadding="0" noHeadline/>
          </TabPanel>
        ))}
      </StyledTabSection>
      <CtaSection headline="See how we can help you" backgroundColor="#f6f6f6"/>
    </Layout>
  )
}

export default FAQs

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

const Container = styled.div`
  text-align:left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height:400px;
  align-items: center;
  @media (${queries.max_break_md}){
      grid-template-columns: 1fr;
      padding:50px 25px 0px;
  }
`;

const TextContainer = styled.div`
  padding:20px;
`;

const Headline = styled.h1`
  color:white;
  font-weight:var(--fontWeight-bold);
  font-size: clamp(var(--fontSize-5), 3.5vw, var(--fontSize-8));
`;

const Subheadline = styled.p`
  color:white;
  font-size: var(--fontSize-2);
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
`;
